import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../../components/Layout";
import EditeurForm from "../../components/EditeurForm";


class EnregistrementEditeurForm extends React.Component {
  render() {
    return (
      <EditeurForm name="enregistrement-editeur" isEnregistrementForm={true}/>
    )
  }
}


const EnregistrementEditeur = ({data}) => {
  const { footerData, navbarData } = data;
  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <title>Confirmation</title>
      </Helmet>
      <EnregistrementEditeurForm/>
    </Layout>
  )
}

export default EnregistrementEditeur;


export const enregistrementEditeurQuery = graphql`
  query EnregistrementEditeur {
    ...LayoutFragment
  }
`